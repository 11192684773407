import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import Markdown from "react-markdown";
import Moment from "react-moment";

import replaceIcons from "../components/functions/global";

import Banner from "../components/banner";

export const query = graphql`
	query TournamentsQuery($slug: String!) {
		strapiTeam(slug: { eq: $slug}){
			slug
			sort
			seo {
				metaTitle
				metaDescription
				shareImage {
					url
				}
			}
			banner {
				title
				description
			}
			longDescription
			videos {
				title
				videoid
				platform
			}
			game {
				name
				short
				logo {
					alternativeText
					caption
					url
				}
			}
			title
			recruiting
		}
		allStrapiTournament(filter: {team: {slug: {eq: $slug}}}, sort: {fields: dateStart, order: DESC}) {
			nodes {
				title
				slug
				description
				dateStart
				dateEnd
				game {
					logo {
						alternativeText
						caption
						url
					}
					name
					short
				}
				url
				image {
					alternativeText
					caption
					url
				}
				country {
					flag {
						alternativeText
						caption
						url
					}
					name
					short
				}
				team {
					slug
				}
				result
			}
		}
		site {
			siteMetadata {
				imagePrefix
			}
		}
	}
`;

const TournamentsPage = ({ data }) => {
	const ateam = data.strapiTeam;
	const tournaments = data.allStrapiTournament;
	const site = data.site;

	var tmpSeo = { metaTitle: "Tournaments", metaDescription: "Subpage of a listing of all the past tournaments this team took parte in."};
	if (ateam.seo !== null) {
		tmpSeo.metaTitle = tmpSeo.metaTitle + " | " + ateam.seo.metaTitle;
		tmpSeo.metaDescription = ateam.seo.metaDescription + " " + tmpSeo.metaDescription;
	}

	var sectionIsAlt = false;
	var sectionStyle = 0;
	var styleClassName = 'style1';

	return (
		<Layout page={ateam.slug} seo={tmpSeo}>
			<Banner banner={ateam.banner}/>

			<section id="wrapper">
				{sectionIsAlt ? sectionIsAlt = false : sectionIsAlt = true}
				<section id="four" className={sectionIsAlt ? "wrapper alt style1" : "wrapper style1"}>
					<div className="inner">
						<a href={"/teams/"+ateam.slug} id="back-button" className="special back">back to team</a>
						<h2 className="major">Team</h2>
						<img className="title" src={site.siteMetadata.imagePrefix + ateam.game.logo.url} alt={ateam.game.logo.alternativeText}/>
						<h3>{ateam.title && ateam.title !== '' ? ateam.title : ateam.game.name}</h3>
						<p>All our past tournaments.</p>
					</div>
				</section>

				{tournaments.nodes.map((atournament, i) => {
					atournament.description = replaceIcons(atournament.description);

					let tmpDate = new Date(atournament.dateStart);
					if (tmpDate < Date.now()) {
						sectionIsAlt ? sectionIsAlt = false : sectionIsAlt = true;
						sectionStyle >= 4 ? sectionStyle = 1 : sectionStyle += 1;
						switch (sectionStyle) {
							case 1: styleClassName = 'style1';
								break;
							case 2: styleClassName = 'style2';
								break;
							case 3: styleClassName = 'style3';
								break;
							case 4: styleClassName = 'style4';
								break;
							default: styleClassName = 'style1';
								break;
						}
						return (
							<section id="one" className={sectionIsAlt ? "wrapper alt spotlight "+styleClassName : "wrapper spotlight "+styleClassName}>
								<div className="inner">
									{atournament.image && atournament.image.url !== null && atournament.image.url !== '' ?
										<a href="#nothing" className="image"><img src={site.siteMetadata.imagePrefix + atournament.image.url} alt={atournament.image.alternativeText} /></a>
									: '' }
									<div className="content">
										<h2 className="major">{atournament.title}</h2>
										<p><Moment format="DD MMMM YYYY">{atournament.dateStart}</Moment></p>
										{atournament.description !== null ?<Markdown source={atournament.description} escapeHtml={false} />:''}
										<a href={"/teams/"+ateam.slug+"/tournaments/"+atournament.slug} className="special">more info</a>
									</div>
								</div>
							</section>
						);
					} else {
						return '';
					}
				})}
			</section>
		</Layout>
	);
};

export default TournamentsPage;
